import React from 'react'

import { Link } from 'gatsby'

const { categoryPath } = require('../functions')

const QAndA = ({ categoriesToArticlesMap }) => {
  const sortedCategories = Object.keys(categoriesToArticlesMap).sort()

  return (
    <div className='q-and-a-categories'>
      {sortedCategories.map((category, index) => {
        const articles = categoriesToArticlesMap[category]

        return (
          <div className='q-and-a-category' key={category}>
            <Link to={categoryPath(category)}>
              <h2 className='q-and-a-category__heading'>{category}</h2>
            </Link>
            <hr className='q-and-a-category__divider' />
            <div>
              {articles.map((article, index) => (
                <Link
                  to={article.path}
                  key={`${category}-article-${index}`}
                  className='q-and-a-category__article-link'
                >
                  {article.heading}
                </Link>
              ))}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default QAndA
