const qAndAPagePath = 'fragor-och-svar'

const articlePath = (slug) => `/${qAndAPagePath}${slug}`

const categoryPath = (category) => `/${qAndAPagePath}/${slugify(category)}`

/**
 * Slugifies given text.
 *
 * Example:
 * slugify('Får vi göra så här?') // far-vi-gora-sa-har
 */
const slugify = (text) =>
  text
    .toString()
    .toLowerCase()
    .normalize('NFD') // The normalize('NFD') will convert accented characters for the most part
    .replace(/[\u0300-\u036f]/g, '') // Remove accented characters for certain combinations
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters such as spaces or tabs
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text

module.exports = {
  qAndAPagePath,
  articlePath,
  categoryPath,
}
