import React from 'react'

import Layout from 'components/Layout'
import Checkmark from 'components/Checkmark'
import QAndA from 'components/QAndA'

const FragorOchSvar = ({ pageContext }) => {
  const { categoriesToArticlesMap } = pageContext

  return (
    <Layout
      seo={{
        title: 'Frågor och svar',
        description:
          'Här finner ni frågor och svar som kan hjälpa er i er dagliga verksamhet.',
      }}
    >
      <oma-grid-row>
        <section className='section'>
          <h1 className='heading'>
            Q&A <Checkmark />
          </h1>
          <p className='ingress'>
            Här finner ni frågor och svar som kan hjälpa er i er dagliga
            verksamhet. Vi jobbar ständigt med att fylla på och utveckla vår Q&A
            för att ni snabbt och lätt ska hitta svaren på era vanligaste
            frågor.
          </p>
          <QAndA categoriesToArticlesMap={categoriesToArticlesMap} />
        </section>
      </oma-grid-row>
    </Layout>
  )
}

export default FragorOchSvar
